import React, { useRef } from 'react';
import { useRouter } from 'next/router';
import LinkFocus from '../link/link';
import Link from 'next/link';
import Content from '../block-content/block-content';
import { sanitySrcUrl, sanityFileUrl } from '../../lib/libFunctions';
// import { itemIds } from '../../lib/navigationObject.js';

import { base, containers, imageStyles, positioning, colors, Img, motion, useScroll, useSpring, animations } from '../../styles/base/_allBase.styles';

import { Button, FooterDiv, TopFooterDiv, BottomFooterDiv, FootItemDiv, YTSDiv, SocialContainer, FlexColumn, FlexRow, LogoContainer, ImgContainer, TextSpan, TextSpanOne } from './footer.styles';
const { H3, H4, StyleP, GridItem_HeaderSection, GridContainer_Page_Layout, GridItem_FooterSection, GridItem_Section, CenterDiv } = base;
const { ImgCover, Image, SmallImage } = imageStyles;
const { ImgContainerBox, ImgContainerCarousel, Card, CardFooter } = containers;
const { motionHover } = animations;
// Assets
import { socialsArray, sunRise, boniberry, twinPeaks } from '../../assets/svg/allSVG';
// functions
import { scrollToRef, createEventOptions } from '../../lib/libFunctions.js';

const privacyPolicy = 'Boni Beach – Privacy Policy';

const ContactDetailsDisplay = ({ info, handleAnalyticsLinkClick }) => {
	return (
		<FlexRow>
			<TextSpanOne>
				<StyleP>{info.label}</StyleP>
			</TextSpanOne>
			<LogoContainer>
				<SmallImage alt={'Boni Beach Logo'} src={info.image} />
			</LogoContainer>
			<a style={{ width: '100%' }} href={info.href} target='_blank' rel='noopener noreferrer' onClick={handleAnalyticsLinkClick}>
				<StyleP style={{ margin: '0rem 0rem' }}>{info.text}</StyleP>
			</a>
		</FlexRow>
	);
};

const SocialsDetailDisplay = ({ data }) => {
	return (
		<FlexRow>
			<TextSpanOne>
				<StyleP>Follow</StyleP>
			</TextSpanOne>
			<LogoContainer>
				<SmallImage alt={'Boni Beach Lombok Boni Berry Logo'} src={boniberry} />
			</LogoContainer>
			<SocialContainer>
				{socialsArray.map((item, i) => {
					const alt = ['instagram', 'facebook'];
					return (
						<a key={i} href={data[i].url} target='_blank' rel='noopener noreferrer'>
							<SmallImage alt={`Link to socail media ${alt[i]}`} style={{ cursor: 'pointer' }} {...motionHover} src={item} />
						</a>
					);
				})}
			</SocialContainer>
		</FlexRow>
	);
};

function Footer({ toTopRef, data, documents, data2 }) {
	const year = new Date().getFullYear();
	const executeScroll = () => scrollToRef(toTopRef);

	const arraycontact = [
		{ label: 'Visit', image: boniberry, text: <Content blocks={data.address} />, href: data.descriptionHref, event: 'footer_map_click' },
		{ label: 'Write', image: sunRise, text: data.email, href: data.emailHref, event: 'footer_email_click' },
		{ label: 'Call', image: twinPeaks, text: data.phoneNumber, href: data.phoneNumberHref, event: 'footer_whatsapp_click' },
	];

	const router = useRouter();
	const { slug } = router.query;

	function handleSendEventClick(slug, eventName) {
		// const eventName = 'whatsapp_click';
		const eventOptions = createEventOptions(slug);

		gtag('event', eventOptions[eventName].event, {
			event_category: eventOptions[eventName].category,
			event_label: eventOptions[eventName].label,
			page_path: eventOptions[eventName].page,
		});

		gtag('event', 'conversion', {
			send_to: 'AW-11381423161/iri1COixhvIYELn4irMq',
		});
	}

	return (
		<FooterDiv>
			<TopFooterDiv>
				<ImgContainer>
					<ImgCover alt={data.mainImage.alt} src={sanitySrcUrl(data.mainImage)} layout='fill' />
				</ImgContainer>
				<FlexColumn>
					{arraycontact.map((contact, i) => {
						return <ContactDetailsDisplay key={i} info={contact} handleAnalyticsLinkClick={() => handleSendEventClick(slug, contact.event)} />;
					})}
					<SocialsDetailDisplay data={data.social} />
				</FlexColumn>
			</TopFooterDiv>
			<BottomFooterDiv>
				<div style={{ width: '100%' }}>
					<Link href={`privacy-policy`}>
						<YTSDiv style={{ cursor: 'pointer' }}>{privacyPolicy}</YTSDiv>
					</Link>
					<a alt={'link tou your tech solutions'} href='https://www.yourtechsolutions.com.au'>
						<YTSDiv style={{ cursor: 'pointer' }}>{`© ${year} Boni Beach Lombok: Built By YTS.`}</YTSDiv>
					</a>
				</div>

				<FootItemDiv>
					<Button alt={'Return to top of Page'} name={'Return to top of page'} onClick={executeScroll}>
						To Top
					</Button>
				</FootItemDiv>
			</BottomFooterDiv>
		</FooterDiv>
	);
}

export { Footer };
